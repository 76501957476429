import React from "react";

const Paragraph = ({ text }) => {
  return (
    <p className="text-text font-primary text-[17px] leading-[26px] font-normal text-left">
      {text}
    </p>
  );
};

export default Paragraph;
