import React, { useState } from "react";
import { Link } from "react-router-dom";
import SevenEleven from "../assets/7111.png";
import Graana from "../assets/graana.jpg";
import RakBank from "../assets/rakbank.jpg";
import Earth from "../assets/earth.jpeg";
import { motion } from "framer-motion";
import img1 from "../assets/tesla/1.jpg";
import img2 from "../assets/tesla/2.jpg";
import img3 from "../assets/tesla/3.jpg";
import ImgSlider from "../utils/ImgSlider";
import Reveal from "../utils/Reveal";
import Title from "../utils/Title";
import Paragraph from "./Paragraph";
import TestSide from "./wordDetails/EARTH";
import Graes from "./wordDetails/GRAANA";
import InteriorMag from "./wordDetails/SevenEleven";
import GRAANAxMushraf from "./wordDetails/GRAANA";
import EARTHxMushraf from "./wordDetails/EARTH";
import RAKBANKxMushraf from "./wordDetails/RAKBANK";
import boy from "../assets/cyybergenius_02.jpeg";
import SevenElevenxMushraf from "./wordDetails/SevenEleven";
import CommonWork from "./wordDetails/CommonWork";
import { Helmet } from "react-helmet";

const workData = [
  {
    img: "/me/20240814_103859.jpg",
    name: "Freelance",
    category: "Online",
    desc: "Some freelance security work done for companies!",
    link: "FreelanceWork",
    component: <CommonWork />,
  },
  {
    img: RakBank,
    name: "RAK Bank",
    category: "UAE - RAK",
    desc: "My time spent in RAK Bank as information security analyst.",
    link: "RAKBANKxMushraf",
    component: <RAKBANKxMushraf />,
  },
  // {
  //   img: Graana,
  //   name: "Graana Properties",
  //   category: "Remote",
  //   desc: "Consultancy with Graana properties as a VAPT professional",
  //   link: "GRAANAxMushraf",
  //   component: <GRAANAxMushraf />,
  // },
  {
    img: SevenEleven,
    name: "7-Eleven",
    category: "UAE - Dubai",
    desc: "Started as data analyst ended up as security practicioner.",
    link: "SevenElevenxMushraf",
    component: <SevenElevenxMushraf />,
  },
  {
    img: Earth,
    name: "Earth Retail",
    category: "UAE - Abu Dhabi",
    desc: "VAPT and IT Operations",
    link: "EARTHxMushraf",
    component: <EARTHxMushraf />,
  },
];

const Work = () => {
  const [job, setJob] = useState({
    img: boy,
    name: "Freelance",
    category: "Online",
    desc: "Some freelance security work done for companies!",
    link: "FreelanceWork",
    component: <CommonWork />,
  });
  const imglist = [img1, img2, img3, img2];
  return (
    <div className="min-h-screen  flex flex-col justify-center items-center pb-32 md:pb-20">
      <Helmet>
        <title>Experience</title>
      </Helmet>
      <Reveal>
        <Title text={"Experience"} />

        {/* <Paragraph initialText="Hello, world!" /> */}
      </Reveal>
      <div className="flex flex-col lg:flex-row flex-wrap 2xl:w-5/6">
        <div className="flex-1 w-full ">
          {workData.map((item, index) => (
            <Reveal box={true} index={index} key={index}>
              <div onClick={() => setJob(item)} className=" cursor-pointer">
                <Link to={item.link} className=" 2xl:pointer-events-none">
                  <div className=" flex justify-start flex-col md:flex-row items-start md:items-center md:gap-10 gap-3 md:my-6 my-4 max-w-[600px] border-2 border-navBorder rounded-2xl p-3 md:p-[6px] workShadow custom-work-hover">
                    <img
                      src={item.img}
                      alt={item.name}
                      className="w-full md:w-[30%] rounded-2xl h-full md:min-h-[180px] xs:h-[180px]  md:max-h-[180px] object-cover"
                    />
                    <div className="flex flex-col items-start gap-2 font-primary">
                      <h4 className="text-base md:text-2xl text-text">
                        {item.name}
                      </h4>
                      <h6 className=" md:block text-xs text-[#6A6A6A]">
                        {item.category}
                      </h6>
                      <p className="hidden md:block text-sm text-text mt-3">
                        {item.desc}
                      </p>
                    </div>
                  </div>
                </Link>
              </div>
            </Reveal>
          ))}
        </div>
        <div className=" flex-1 w-full  text-text  border-2 border-navBorder rounded-3xl hidden 2xl:block ">
          <div className=" sticky top-0 overflow-scroll h-[80%] xs:px-4  pb-10 pt-10 no-scrollbar  ">
            {job.component}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Work;
