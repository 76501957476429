import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import {
  BsArrowLeft,
  BsBriefcase,
  BsInfoCircle,
  BsLightningCharge,
} from "react-icons/bs";
import { HiOutlineMail } from "react-icons/hi";
import { LuPencilLine } from "react-icons/lu";
import { RiCompassFill, RiHome5Line } from "react-icons/ri";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

const Navbar = () => {
  const [selectedLink, setSelectedLink] = useState(false);
  const location = useLocation();
  const currentPathName = location.pathname;

  useEffect(() => {
    if (
      // currentPathName === "/blog/how-i-use-my-ipad-in-my-design-process" ||
      // currentPathName === "/blog/fav-sketch-plugins" ||
      // currentPathName === "/blog/wireframe-process" ||
      currentPathName.startsWith(`/work/`) ||
      currentPathName.startsWith(`/blog/`) ||
      currentPathName.startsWith(`/projects/`)
    ) {
      setSelectedLink(true);
    } else {
      setSelectedLink(false);
    }
  }, [currentPathName]);

  const navigate = useNavigate();
  return (
    <>
      <div className="fixed bottom-[2px] sm:bottom-[-23px] custom1440:top-[50%] left-[50%] custom1440:left-12 translate-x-[-50%] translate-y-[-50%] text-2xl text-[#5f5f5f] flex justify-center items-center  gap-3 flex-row custom1440:flex-col">
        {selectedLink && (
          <motion.button
            variants={{
              hidden: { opacity: 0, marginLeft: -100 },
              visible: { opacity: 1, marginLeft: 0 },
            }}
            initial="hidden"
            animate="visible"
            transition={{ duration: 0.5 }}
            onClick={() => navigate(-1)}
            className={`bg-[#1e1e1e] p-2 sm:p-3 rounded-2xl workShadow`}
          >
            <div
              className={`bg-main text-xl sm:text-2xl p-[4px] sm:p-[6px] rounded-lg sm:rounded-xl cursor-pointer border-4 hover:text-white  border-main`}
            >
              <BsArrowLeft className="text-text " />
            </div>
          </motion.button>
        )}
        <div
          className={`flex justify-center items-center gap-3 bg-[#1e1e1e] p-2 sm:p-3 rounded-2xl flex-row custom1440:flex-col workShadow ${
            !selectedLink ? "transition-transform duration-700 ease-in-out" : ""
          }`}
        >
          <NavLink to={"/"}>
            {({ isActive, isPending }) => (
              <div
                className={`bg-main p-[2px] sm:p-[6px] xs:w-[2rem] sm:w-full rounded-lg sm:rounded-xl cursor-pointer border-4 hover:text-white  ${
                  isActive ? "border-main sm:border-navBorder" : "border-main"
                }`}
              >
                <img src="/logomm.png" className={"invert  bg-cover h-6 w-6"} />
                {/* <RiHome5Line className={isActive && "text-text "} /> */}
              </div>
            )}
          </NavLink>
          <NavLink to={"/work"}>
            {({ isActive, isPending }) => (
              <div
                className={`bg-main text-xl sm:text-2xl p-[4px] sm:p-[6px] rounded-lg sm:rounded-xl cursor-pointer border-4 hover:text-white  ${
                  isActive ? "border-main sm:border-navBorder" : "border-main"
                }`}
              >
                <BsBriefcase className={isActive && "text-text "} />
              </div>
            )}
          </NavLink>
          <NavLink to={"/blog"}>
            {({ isActive, isPending }) => (
              <div
                className={`bg-main text-xl sm:text-2xl p-[4px] sm:p-[6px] rounded-lg sm:rounded-xl cursor-pointer border-4 hover:text-white  ${
                  isActive ? "border-main sm:border-navBorder" : "border-main"
                }`}
              >
                <LuPencilLine className={isActive && "text-text "} />
              </div>
            )}
          </NavLink>
          <NavLink to={"/about"}>
            {({ isActive, isPending }) => (
              <div
                className={`bg-main text-xl sm:text-2xl p-[4px] sm:p-[6px] rounded-lg sm:rounded-xl cursor-pointer border-4 hover:text-white  ${
                  isActive ? "border-main sm:border-navBorder" : "border-main"
                }`}
              >
                <BsInfoCircle className={isActive && "text-text "} />
              </div>
            )}
          </NavLink>
          <NavLink to={"/contact"}>
            {({ isActive, isPending }) => (
              <div
                className={`bg-main text-xl sm:text-2xl p-[4px] sm:p-[6px] rounded-lg sm:rounded-xl cursor-pointer border-4 hover:text-white  ${
                  isActive ? "border-main sm:border-navBorder" : "border-main"
                }`}
              >
                <HiOutlineMail className={isActive && "text-text "} />
              </div>
            )}
          </NavLink>
        </div>
      </div>
    </>
  );
};

export default Navbar;
