import { BrowserRouter, Route, Routes } from "react-router-dom";
import About from "./components/about";
import Blog from "./components/blog";

import Design from "./components/blogDetails/aievproject.js";
import WireFrame from "./components/blogDetails/HackRfAttacks.js";
import Contact from "./components/contact";
import Home from "./components/home";
import Navbar from "./components/navbar";
import Graes from "./components/wordDetails/GRAANA.js";
import InteriorMag from "./components/wordDetails/SevenEleven.js";
import RAKBANKxMushraf from "./components/wordDetails/RAKBANK";
import Work from "./components/work";
import ScrollToTop from "./utils/ScrollToTop";
import GRAANAxMushraf from "./components/wordDetails/GRAANA.js";
import SevenElevenxMushraf from "./components/wordDetails/SevenEleven.js";
import EARTHxMushraf from "./components/wordDetails/EARTH.js";
import CommonWork from "./components/wordDetails/CommonWork.js";
import X11Forward from "./components/blogDetails/x11forwarding.js";
import Aievproject from "./components/blogDetails/aievproject.js";
import HackRfAttacks from "./components/blogDetails/HackRfAttacks.js";
import Call from "./components/Call.js";

function App() {
  return (
    <div className="App bg-main relative px-3 sm:px-0">
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          {/* Common Pages and Error Handling */}
          <Route path="/" element={<Home />} />
          <Route path="/*" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
          {/* Blog Pages */}
          <Route path="/blog" element={<Blog />} />
          <Route path="/projects/AIEV" element={<Aievproject />} />
          <Route path="/blog/x11-forwarding" element={<X11Forward />} />
          <Route path="/blog/HackRf-attacks" element={<HackRfAttacks />} />
          {/* Work Experience Routes */}
          <Route path="/work" element={<Work />} />
          <Route path="/work/RAKBANKxMushraf" element={<RAKBANKxMushraf />} />
          <Route
            path="/work/SevenElevenxMushraf"
            element={<SevenElevenxMushraf />}
          />
          <Route path="/work/GRAANAxMushraf" element={<GRAANAxMushraf />} />
          <Route path="/work/EARTHxMushraf" element={<EARTHxMushraf />} />
          <Route path="/work/FreelanceWork" element={<CommonWork />} />
          <Route path="/call" element={<Call />} />
        </Routes>
        <Navbar />
      </BrowserRouter>
    </div>
  );
}

export default App;
