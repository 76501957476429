import { motion } from "framer-motion";
import React from "react";
import img1 from "../../assets/tesla/1.jpg";
import img2 from "../../assets/tesla/2.jpg";
import SevenEleven from "../../assets/7111.png";

import img3 from "../../assets/tesla/3.jpg";
import ImgSlider from "../../utils/ImgSlider";
import { BsBriefcase } from "react-icons/bs";
import ButtonVisit from "../../utils/ButtonVisit.";
import EmojiBtn from "../../utils/EmojiBtn";
import { Helmet } from "react-helmet";

const SevenElevenxMushraf = () => {
  const imglist = [img1, img2, img3, img2];
  return (
    <div className="min-h-screen max-w-[650px] mx-auto font-primary pb-32 2xl:pb-20">
      <Helmet>
        <title>Experience at 7-Eleven </title>
        <meta name="description" content="Experience at 7-Eleven - Mushraf" />
      </Helmet>

      <div className="flex justify-between items-center sm:pt-[170px]  pt-[70px] 2xl:pt-8 pb-12">
        <motion.h2
          variants={{
            hidden: { opacity: 0, x: 50, y: 50, scale: 0.68 },
            visible: { opacity: 1, x: 0, y: 0, scale: 1 },
          }}
          initial="hidden"
          animate="visible"
          transition={{ duration: 0.75, delay: 0.25 }}
          className="text-text text-[40px] font-light"
        >
          7-Eleven <br />
          <div className="hidden md:block text-sm text-[#6A6A6A]">
            7 – Eleven – Dubai, United Arab Emirates
          </div>
        </motion.h2>
        <motion.a
          variants={{
            hidden: { opacity: 0, x: -50, y: 50, scale: 0.68 },
            visible: { opacity: 1, x: 0, y: 0, scale: 1 },
          }}
          initial="hidden"
          animate="visible"
          transition={{ duration: 0.75, delay: 0.25 }}
          href="#"
          className="inline text-text    text-sm border-2 border-customBorder rounded-[30px] p-1  cursor-pointer"
        >
          <img src={SevenEleven} className=" rounded-3xl  w-24" />
        </motion.a>
      </div>
      <motion.h2
        variants={{
          hidden: { opacity: 0, x: 50, y: 50, scale: 0.68 },
          visible: { opacity: 1, x: 0, y: 0, scale: 1 },
        }}
        initial="hidden"
        animate="visible"
        transition={{ duration: 0.75, delay: 0.25 }}
      >
        <div className="w-full flex flex-row justify-between pb-5">
          <div>Started: Apr 2019</div>

          <div>Ended: Dec 2020</div>
        </div>

        <div className="w-full  flex flex-row justify-center pb-10">
          <div className="flex gap-4 flex-row  ">
            {/* <div className="  text-2xl">
            <BsBriefcase />
          </div> */}
            <div className="text-text ">Data Analyst</div>
          </div>
        </div>
      </motion.h2>
      {/* <motion.div
        variants={{
          hidden: {
            opacity: 0,
            paddingTop: 100,
            transform: "skewY(0.04rad)",
            scale: 0.4,
          },
          visible: {
            opacity: 1,
            paddingTop: 0,
            transform: "skewY(0)",
            scale: 1,
          },
        }}
        initial="hidden"
        animate="visible"
        transition={{
          delay: 0.4,
          duration: 1,
        }}
      >
        <ImgSlider imgList={imglist} />
      </motion.div> */}
      <hr />
      <motion.div
        variants={{
          hidden: {
            opacity: 0,
            paddingTop: 100,
            transform: "skewY(0.04rad)",
            scale: 0.4,
          },
          visible: {
            opacity: 1,
            paddingTop: 0,
            transform: "skewY(0)",
            scale: 1,
          },
        }}
        initial="hidden"
        animate="visible"
        transition={{
          delay: 0.4,
          duration: 1,
        }}
        className="flex flex-col gap-8 mt-16"
      >
        <h3 className="text-text text-2xl font-normal">Summary</h3>
        <p className="text-text text-[15px] leading-[26px] font-normal">
          As a Data Analyst with 7-Eleven, I was passionate about turning data
          into actionable insights. I’ve helped drive sales strategies, manage
          financial reports, and create budgets, all while improving operational
          efficiency.
        </p>
        <p className="text-text text-[15px] leading-[26px] font-normal">
          Let’s connect and explore how I can bring this expertise to your team!
        </p>
        <motion.div
          variants={{
            hidden: {
              opacity: 0,
              paddingTop: 100,
              transform: "skewY(0.04rad)",
              scale: 0.4,
            },
            visible: {
              opacity: 1,
              paddingTop: 0,
              transform: "skewY(0)",
              scale: 1,
            },
          }}
          initial="hidden"
          animate="visible"
          transition={{
            delay: 0.4,
            duration: 1,
          }}
        >
          {/* <ImgSlider imgList={imglist} /> */}
        </motion.div>
        <h6 className="text-text text-lg font-normal">Key Tasks</h6>
        <div className="text-text text-[15px] leading-[26px] font-normal">
          <ul className=" px-2">
            <li className=" list-disc">
              Providing expertise in data analysis, structures, data mining, and
              data cleansing.
            </li>
            <li className=" list-disc">
              Forecasting the sales data and setting targets for individual
              stores as per company strategies.
            </li>
            <li className=" list-disc">
              Handling PSA and category analysis, reporting on demand.
            </li>
            <li className=" list-disc">
              Comparison based analysis and reporting.
            </li>
            <li className=" list-disc">
              Keeping Income statements records, and P&L creation for monthly
              reporting.
            </li>
            <li className=" list-disc">
              Creating the yearly budgets and sales target for stores.
            </li>
            <li className=" list-disc">
              {" "}
              Organizational planning and increase the effectiveness and
              efficiency of work.
            </li>
          </ul>
        </div>
        <p className="text-text text-[15px] leading-[26px] font-normal">
          <span className=" font-bold">Skills: </span> Data Analysis, Data
          Visualization, SQL Querying, Forecasting, Data Cleaning, Reporting,
          Data Integration, Trend Analysis.
        </p>
        <div className="flex row gap-5 flex-wrap">
          <ButtonVisit text="Interested??" link="/contact" />

          <EmojiBtn />
          <EmojiBtn text="share" />
        </div>
      </motion.div>
    </div>
  );
};

export default SevenElevenxMushraf;
