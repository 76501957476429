/* eslint-disable react-hooks/exhaustive-deps */
import { motion, useAnimation, useInView } from "framer-motion";
import React, { useEffect, useRef } from "react";

const Reveal = ({ children, Style, box, index }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView]);

  return (
    <div ref={ref}>
      <motion.div
        variants={
          box
            ? {
                hidden: {
                  opacity: 0,
                  paddingTop: 100,
                  transform: "skewY(0.04rad)",
                },
                visible: { opacity: 1, paddingTop: 0, transform: "skewY(0)" },
              }
            : {
                hidden: { opacity: 0, y: 75 },
                visible: { opacity: 1, y: 0 },
              }
        }
        initial="hidden"
        animate={mainControls}
        transition={
          box
            ? {
                delay: 0.2 + index / 7,
                type: "spring",
                duration: 3,
              }
            : { duration: 0.5, delay: 0.25 }
        }
        className={Style}
      >
        {children}
      </motion.div>
    </div>
  );
};

export default Reveal;
