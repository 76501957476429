import Design from "../assets/design.jpg";
import Sketch from "../assets/sketch.jpg";
import Wireframe from "../assets/wireframe.jpg";
export const BlogData = [
  {
    img: "/me/AIV.png",
    name: "AI Enhanced Vehicle  System - Securing the Future",
    category: "#Privacy #AI",
    date: "24/01/2024",
    link: "/projects/AIEV",
  },
  {
    img: "/me/xserver.webp",
    name: "X11 Forwasrding - Simplified Remote  GUI Execution",
    category: "#Tricks #Linux",
    date: "12/06/2024",
    link: "x11-forwarding",
  },

  {
    img: "/me/hackrf.jpg",
    name: "HackRF - Common Use Cases ",
    category: "#Hacking #SDR",
    date: "09/02/2024",
    link: "HackRf-attacks",
  },
];
