import React from "react";

const Title = ({ text, style }) => {
  return (
    <h2
      className={`text-center font-light font-primary text-[40px] text-text mt-[60px] sm:mt-[120px]  ${
        !style ? "mb-[60px]" : style
      }`}
    >
      {text}
    </h2>
  );
};

export default Title;
