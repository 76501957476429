import { motion } from "framer-motion";
import Title from "./Title";
import Reveal from "./Reveal";
export const SinglePost = ({
  postTitle,
  postDate,
  Postimg,
  children,
  donateText = "🤍 Project Needs Donations 🤍 Fundings ❤️",
  donation = false,
}) => {
  return (
    <div className="min-h-screen flex flex-col justify-center max-w-[650px]  2xl:max-w-[1050px] mx-auto font-primary pb-32 2xl:pb-20 overflow-hidden">
      <motion.div
        variants={{
          hidden: { opacity: 0, marginTop: "150px", scale: 0.68 },
          visible: { opacity: 1, marginTop: 0, scale: 1 },
        }}
        initial="hidden"
        animate="visible"
        transition={{ delay: 0.25, duration: 1 }}
      >
        <Title text={postTitle} style={"mb-[20px] text-center"} />
        <h6 className="text-[#6A6A6A] text-lg text-center">{postDate}</h6>
      </motion.div>
      <div className="w-full  relative">
        <motion.img
          variants={{
            hidden: {
              opacity: 0,
              paddingTop: 100,
              transform: "skewY(0.04rad)",
              scale: 0.4,
            },
            visible: {
              opacity: 1,
              paddingTop: 0,
              transform: "skewY(0)",
              scale: 1,
            },
          }}
          initial="hidden"
          animate="visible"
          transition={{
            delay: 0.4,
            duration: 1,
          }}
          src={Postimg}
          alt="design-img"
          className="rounded-2xl my-12 h-[350px] w-full object-cover   "
        />

        {donation ? (
          <motion.a
            initial="hidden"
            animate="visible"
            whileHover={{ borderColor: "rgba(218, 218, 218, 0.2)" }}
            href={"/contact"}
          >
            <div className="absolute -right-5 w-[190px] bottom-14  bg-black text-white  text-lg py-2 flex">
              <marquee className="w-full">{donateText}</marquee>
            </div>
          </motion.a>
        ) : (
          ""
        )}
      </div>
      <div className="flex flex-col gap-7">{children}</div>
    </div>
  );
};

export default SinglePost;
