import { motion } from "framer-motion";
import ButtonVisit from "../../utils/ButtonVisit.";
import { Helmet } from "react-helmet";
const CommonWork = () => {
  const companies = [
    { name: "Oracle", emoji: "🟧" },
    { name: "Dell", emoji: "💻" },
    { name: "Intel", emoji: "🧠" },
    { name: "Sony", emoji: "🎧" },
    { name: "Sound Cloud Music", emoji: "🎵" },
    { name: "OLX Company", emoji: "📦" },
    { name: "ESET Antivirus", emoji: "🛡️" },
    { name: "Graana Properties", emoji: "🏢" },
    { name: "7-Eleven UAE", emoji: "🛒" },
    { name: "Sky Scanner", emoji: "✈️" },
    { name: "BuzzFeed", emoji: "📰" },
    { name: "Docker", emoji: "🐋" },
    { name: "IBM", emoji: "🖥️" },
    { name: "Legal Robot", emoji: "🤖" },
    { name: "OnePageCRM", emoji: "📋" },
    { name: "Humble Bundle", emoji: "🎁" },
    { name: "Microsoft", emoji: "💻" },
    { name: "Sophos", emoji: "🛡️" },
    { name: "Constant Contact", emoji: "📧" },
    { name: "Symantec", emoji: "🔒" },
    { name: "JotForm", emoji: "📝" },
    { name: "Dutch-Gov (NCSC)", emoji: "🇳🇱" },
    { name: "MailGun", emoji: "📬" },
    { name: "DesconLLC", emoji: "🏗️" },
    { name: "Intercom", emoji: "💬" },
    { name: "Caviar", emoji: "🍽️" },
    { name: "Poster Mywall", emoji: "🖼️" },
    { name: "Print Arabia", emoji: "🖨️" },
    { name: "Indeed", emoji: "🔍" },
    { name: "Threat Stack", emoji: "🚨" },
    { name: "Philip Capital LLC", emoji: "💼" },
    { name: "BugCrowd", emoji: "🐛" },
    { name: "Pinterest", emoji: "📌" },
    { name: "YNAB", emoji: "💸" },
    { name: "Centrify", emoji: "🔐" },
    { name: "SmartSheet", emoji: "📊" },
    { name: "Spotify", emoji: "🎵" },
    { name: "Silent Circle", emoji: "🔇" },
    { name: "Tempered Networks", emoji: "🔐" },
    { name: "WakaTime", emoji: "⏱️" },
    { name: "Souq", emoji: "🛍️" },
    { name: "Github", emoji: "🐙" },
    { name: "Coin Falcon", emoji: "🪙" },
    { name: "Fig", emoji: "🍇" },
    { name: "Zomato", emoji: "🍴" },
    { name: "Mail.ru", emoji: "📧" },
    { name: "LifeWire", emoji: "💡" },
    { name: "Segment", emoji: "🔢" },
    { name: "Workable", emoji: "🛠️" },
    { name: "Mahara Org.", emoji: "🌐" },
    { name: "AlienVault", emoji: "👽" },
    { name: "Awoke", emoji: "🌅" },
    { name: "Coalition LLC", emoji: "🤝" },
    { name: "Groupon", emoji: "🛒" },
    { name: "MBME Pay", emoji: "💳" },
    { name: "Antihack.me", emoji: "🛡️" },
    { name: "Azendoo", emoji: "🗂️" },
    { name: "Edmodo", emoji: "📚" },
    { name: "Hacker1", emoji: "🕵️‍♂️" },
    { name: "Bitdefender", emoji: "🛡️" },
    { name: "&MORE ", emoji: "➕✨" },
  ];
  return (
    <>
      <Helmet>
        <title>Freelance Work - Mushraf</title>
        <meta name="description" content="Freelance Work - Mushraf" />
      </Helmet>
      <div className="min-h-screen max-w-[650px] mx-auto font-primary pb-32 2xl:pb-20">
        <div className="flex justify-between items-center sm:pt-[170px]  pt-[70px] 2xl:pt-8 pb-12">
          <motion.h2
            variants={{
              hidden: { opacity: 0, x: 50, y: 50, scale: 0.68 },
              visible: { opacity: 1, x: 0, y: 0, scale: 1 },
            }}
            initial="hidden"
            animate="visible"
            transition={{ duration: 0.75, delay: 0.25 }}
            className="text-text text-[40px] font-light"
          >
            Bug Bounties and Hackathons 🐞
            <br />
            <div className="hidden md:block text-sm text-[#6A6A6A]">
              Security Bugs Discovered in Freelance Projects as bug bounty
              hunter
              {/* 🛡️ */}
            </div>
          </motion.h2>
          {/* <motion.a
            variants={{
              hidden: { opacity: 0, x: -50, y: 50, scale: 0.68 },
              visible: { opacity: 1, x: 0, y: 0, scale: 1 },
            }}
            initial="hidden"
            animate="visible"
            transition={{ duration: 0.75, delay: 0.25 }}
            href="#"
            className="inline text-text    text-sm border-2 border-customBorder rounded-[30px] p-1  cursor-pointer"
          >
            <img src={RakBank} className=" rounded-3xl  w-24" />
          </motion.a> */}
        </div>
        <motion.h2
          variants={{
            hidden: { opacity: 0, x: 50, y: 50, scale: 0.68 },
            visible: { opacity: 1, x: 0, y: 0, scale: 1 },
          }}
          initial="hidden"
          animate="visible"
          transition={{ duration: 0.75, delay: 0.25 }}
        >
          {/* <div className="w-full flex flex-row justify-between pb-5"> */}
          {/* <div>Started: 2018</div> */}

          {/* <div>Ended: 2019</div> */}
          {/* </div> */}

          <div className="w-full  flex flex-row justify-center pb-5"></div>
        </motion.h2>

        {/* <hr /> */}
        <motion.div
          variants={{
            hidden: {
              opacity: 0,
              paddingTop: 100,
              transform: "skewY(0.04rad)",
              scale: 0.4,
            },
            visible: {
              opacity: 1,
              paddingTop: 0,
              transform: "skewY(0)",
              scale: 1,
            },
          }}
          initial="hidden"
          animate="visible"
          transition={{
            delay: 0.4,
            duration: 1,
          }}
          className="flex flex-col gap-2 "
        >
          {/* <h3 className="text-text text-2xl font-normal">Summary</h3> */}
          <p className="text-text text-[15px] leading-[26px] font-normal">
            Identified and acknowledged security vulnerabilities in various
            freelance projects, recognized by several companies.
          </p>

          <motion.div
            variants={{
              hidden: {
                opacity: 0,
                paddingTop: 100,
                transform: "skewY(0.04rad)",
                scale: 0.4,
              },
              visible: {
                opacity: 1,
                paddingTop: 0,
                transform: "skewY(0)",
                scale: 1,
              },
            }}
            initial="hidden"
            animate="visible"
            transition={{
              delay: 0.4,
              duration: 1,
            }}
          >
            {/* <ImgSlider imgList={imglist} /> */}
          </motion.div>
          <div className="flex flex-wrap">
            {companies.map(({ name, emoji }) => (
              <div key={name} className="mt-8 mx-2">
                <motion.a
                  whileHover={{ borderColor: "rgba(218, 218, 218, 0.2)" }}
                  className="inline text-text text-sm border-2 border-customBorder rounded-[30px] px-8 py-[14px] cursor-pointer"
                >
                  {emoji} {name}
                </motion.a>
              </div>
            ))}
          </div>
          {/* <h6 className="text-text text-lg font-normal">Keywords</h6> */}

          {/* <div className="mt-5">
            <span className="text-xl">➕ More ✨</span>
          </div> */}

          <div className="mt-10">
            <ButtonVisit text="Interested?" link="/contact" />
          </div>
          {/* <ButtonVisit text="Hire Me" link="https://rakbank.ae" /> */}
        </motion.div>
      </div>
    </>
  );
};

export default CommonWork;
