import { motion } from "framer-motion";
import React from "react";
import img1 from "../../assets/tesla/1.jpg";
import img2 from "../../assets/tesla/2.jpg";
import Graana from "../../assets/graana.jpg";
import img3 from "../../assets/tesla/3.jpg";

import ImgSlider from "../../utils/ImgSlider";
import { BsBriefcase } from "react-icons/bs";
import ButtonVisit from "../../utils/ButtonVisit.";

const GRAANAxMushraf = () => {
  const imglist = [img1, img2, img3, img2];
  return (
    <div className="min-h-screen max-w-[650px] mx-auto font-primary pb-32 2xl:pb-20">
      <div className="flex justify-between items-center sm:pt-[170px]  pt-[70px] 2xl:pt-8 pb-12">
        <motion.h2
          variants={{
            hidden: { opacity: 0, x: 50, y: 50, scale: 0.68 },
            visible: { opacity: 1, x: 0, y: 0, scale: 1 },
          }}
          initial="hidden"
          animate="visible"
          transition={{ duration: 0.75, delay: 0.25 }}
          className="text-text text-[40px] font-light"
        >
          GRAANA Properties
          <br />
          <div className="hidden md:block text-sm text-[#6A6A6A]">
            National Bank of Ras Al Khaimah - United Arab Emirates
          </div>
        </motion.h2>
        <motion.a
          variants={{
            hidden: { opacity: 0, x: -50, y: 50, scale: 0.68 },
            visible: { opacity: 1, x: 0, y: 0, scale: 1 },
          }}
          initial="hidden"
          animate="visible"
          transition={{ duration: 0.75, delay: 0.25 }}
          href="#"
          className="inline text-text    text-sm border-2 border-customBorder rounded-[30px] p-1  cursor-pointer"
        >
          <img src={Graana} className=" rounded-3xl  w-24" />
        </motion.a>
      </div>
      <motion.h2
        variants={{
          hidden: { opacity: 0, x: 50, y: 50, scale: 0.68 },
          visible: { opacity: 1, x: 0, y: 0, scale: 1 },
        }}
        initial="hidden"
        animate="visible"
        transition={{ duration: 0.75, delay: 0.25 }}
      >
        <div className="w-full flex flex-row justify-between pb-5">
          <div>Started: 2018</div>

          <div>Ended: 2019</div>
        </div>

        <div className="w-full  flex flex-row justify-center pb-10">
          <div className="flex gap-4 flex-row  ">
            {/* <div className="  text-2xl">
            <BsBriefcase />
          </div> */}
            <div className="text-text ">
              SOC Analyst - Security Operations Center
            </div>
          </div>
        </div>
      </motion.h2>
      {/* <motion.div
        variants={{
          hidden: {
            opacity: 0,
            paddingTop: 100,
            transform: "skewY(0.04rad)",
            scale: 0.4,
          },
          visible: {
            opacity: 1,
            paddingTop: 0,
            transform: "skewY(0)",
            scale: 1,
          },
        }}
        initial="hidden"
        animate="visible"
        transition={{
          delay: 0.4,
          duration: 1,
        }}
      >
        <ImgSlider imgList={imglist} />
      </motion.div> */}
      <hr />
      <motion.div
        variants={{
          hidden: {
            opacity: 0,
            paddingTop: 100,
            transform: "skewY(0.04rad)",
            scale: 0.4,
          },
          visible: {
            opacity: 1,
            paddingTop: 0,
            transform: "skewY(0)",
            scale: 1,
          },
        }}
        initial="hidden"
        animate="visible"
        transition={{
          delay: 0.4,
          duration: 1,
        }}
        className="flex flex-col gap-8 mt-16"
      >
        <h3 className="text-text text-2xl font-normal">Summary</h3>
        <p className="text-text text-[15px] leading-[26px] font-normal">
          Developed SOC automation strategies and performed incident response
          and threat hunting to enhance cybersecurity; Also conducted security
          assessments and compliance tests.
        </p>
        <p className="text-text text-[15px] leading-[26px] font-normal">
          I also performed log analysis to enhance our cybersecurity posture.
          Additionally, I led penetration testing and reverse engineering
          efforts to identify and mitigate security risks.
        </p>
        <motion.div
          variants={{
            hidden: {
              opacity: 0,
              paddingTop: 100,
              transform: "skewY(0.04rad)",
              scale: 0.4,
            },
            visible: {
              opacity: 1,
              paddingTop: 0,
              transform: "skewY(0)",
              scale: 1,
            },
          }}
          initial="hidden"
          animate="visible"
          transition={{
            delay: 0.4,
            duration: 1,
          }}
        >
          {/* <ImgSlider imgList={imglist} /> */}
        </motion.div>
        <h6 className="text-text text-lg font-normal">Key Tasks</h6>
        <p className="text-text text-[15px] leading-[26px] font-normal">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed
          odio dui. Cum sociis natoque penatibus et magnis dis parturient
          montes, nascetur ridiculus mus. Duis mollis, est non commodo luctus,
          nisi erat porttitor ligula, eget lacinia odio sem nec elit. Cras
          mattis consectetur purus sit amet fermentum.
        </p>
        <p className="text-text text-[15px] leading-[26px] font-normal">
          <ul className=" px-2">
            <li className=" list-disc">
              Patch management and vulnerability management of Bank’s assets
              ~4k. Nessus, Qualys and MS.
            </li>
            <li className=" list-disc">
              • Working on reported IOCs and updating in Bank’s database.
            </li>
            <li className=" list-disc">
              Conducted secure configuration test on IT infrastructure ~2k
              assets using Nessus and Qualys..
            </li>
            <li className=" list-disc">
              Conducted assessments per PCI DSS requirement #11 for ~600 assets.
            </li>
            <li className=" list-disc">
              {" "}
              Developed and implemented the scanning and reporting strategy and
              tool for workflow automation of SOC.{" "}
            </li>
            <li className=" list-disc">
              {" "}
              Conducted vulnerability assessments on Bank’s servers, network
              devices and endpoints in scope.
            </li>
            <li className=" list-disc">
              {" "}
              Log Analysis from various network devices and applications.{" "}
            </li>
            <li>
              {" "}
              Perform attack simulations on company systems and web applications
              to determine the exploit risk & security flaws.
            </li>
          </ul>
          • Document and discuss security findings with information technology
          teams. • Analysing triaged malicious programs and code by conducting
          reverse engineering techniques and tools, as well as checking
          behaviour and testing same in virtual machines. • Perform Security
          SIEM Operational task - Analysis, Filters, Active channels, Reports,
          Suggestion of fine tuning on existing rules and monitor IOC
          (Indicators of Compromise).
        </p>

        <h6 className="text-text text-lg font-normal">Lorem Nibh</h6>
        <p className="text-text text-[15px] leading-[26px] font-normal">
          Maecenas faucibus mollis interdum. Maecenas sed diam eget risus varius
          blandit sit amet non magna. Nulla vitae elit libero, a pharetra augue.
          Cras mattis consectetur purus sit amet fermentum. Nulla vitae elit
          libero, a pharetra augue. Cras mattis consectetur purus sit amet
          fermentum.
        </p>
        <div className="flex row gap-5">
          <ButtonVisit text="Visit GRAANA" link="https://graana.com" />
          <ButtonVisit text="Get in Touch" link="/contact" target="_self" />
        </div>
      </motion.div>
    </div>
  );
};

export default GRAANAxMushraf;
