import React from "react";

const SemiTitle = ({ text, style, hover }) => {
  return (
    <>
      {hover ? (
        <h3
          className={`text-text hover:text-[#6a6a6a] font-primary text-2xl font-normal text-left ${style}`}
        >
          {text}
        </h3>
      ) : (
        <h3
          className={`text-text font-primary text-2xl font-normal text-left ${style}`}
        >
          {text}
        </h3>
      )}
    </>
  );
};

export default SemiTitle;
