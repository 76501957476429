import React, { useState } from "react";
import { TECollapse } from "tw-elements-react";
import ButtonVisit from "./ButtonVisit.";
import EmojiBtn from "./EmojiBtn";
import LinkOut from "./LinkOut";

const accordionData = [
  {
    id: "Security Testing ",
    element: "Shh",
    question: "Security Assessments",
    answer:
      " Expertise in white and black box testing methods to thoroughly identify and address security vulnerabilities. Focuses on ensuring robust protection by simulating real-world attack scenarios and implementing effective mitigation strategies.",
    label: "collapse1",
  },
  {
    id: " Secure Cloud and Web Solutions",
    element: "Web Works ",
    question: " Secure Cloud and Web Solutions",
    answer:
      "Specializes in building secure, scalable cloud-based software and delivering both 3D and traditional immersive web products.",
    label: "collapse2",
  },
  {
    id: "headingThree",
    element: "Consulting",
    question: "Consulting",
    answer:
      "Providing advice and tailored technology solutions either it involves infrastructural security or its related to web tech.",
    label: "collapse3",
  },
];

const Accordion = () => {
  const [show, setShow] = useState({
    collapse1: false,
    collapse2: false,
    collapse3: false,
  });

  const toggleShow = (label) => {
    setShow((prevShow) => ({
      ...prevShow,
      [label]: !prevShow[label],
    }));
  };

  return (
    <>
      <div id="accordionExample">
        {accordionData.map((item, index) => (
          <div
            key={index}
            className="workShadow rounded-xl border-2 border-customBorder mb-4 px-2 py-1"
          >
            <h2 className="mb-0" id={item.id}>
              <button
                className={`group relative flex w-full items-center px-5 py-4 text-left text-lg text-text transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none  `}
                type="button"
                onClick={() => toggleShow(item.label)}
                aria-expanded={show[item.label]}
                aria-controls={item.label}
              >
                {item.question}
                <span
                  className={`${
                    show[item.label]
                      ? `rotate-[-180deg] -mr-1`
                      : `rotate-0 text-[#6A6A6A] `
                  } ml-auto h-5 w-5 shrink-0 fill-[#336dec] transition-transform duration-200 ease-in-out motion-reduce:transition-none`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-6 w-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                </span>
              </button>
            </h2>

            <TECollapse
              show={show[item.label]}
              className="!mt-0 !rounded-b-none !shadow-none z-5"
            >
              <div
                className="px-5 py-4 text-[#6A6A6A] text-base z-4 cursor-pointer"
                onClick={() => toggleShow(item.label)}
                aria-expanded={show[item.label]}
                aria-controls={item.label}
              >
                {item.answer}
              </div>
            </TECollapse>
          </div>
        ))}
      </div>
    </>
  );
};

export default Accordion;
