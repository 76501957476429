/* eslint-disable react/style-prop-object */
import React from "react";

import Paragraph from "../../utils/Paragraph";
import SemiTitle from "../../utils/SemiTitle";
import Title from "../../utils/Title";
import { BlogData } from "../Data";
import SinglePost from "../../utils/SinglePost";
import EmojiBtn from "../../utils/EmojiBtn";
import ButtonVisit from "../../utils/ButtonVisit.";
import { Helmet } from "react-helmet";

const HackRfAttacks = () => {
  return (
    <>
      <Helmet>
        <title>{BlogData[2].name}</title>
        <meta name="description" content={BlogData[2].category} />
      </Helmet>
      <SinglePost
        postTitle={BlogData[2].name}
        Postimg={BlogData[2].img}
        postDate={BlogData[2].date}
      >
        <SemiTitle text={"Tell me about HackRF One"} />
        <Paragraph text="Well the HackRF One SDR is the most popular SDR devices on the market. The term SDR stands for 'Software Defined Radio' and it is used to aggregate radio communication systems that implement in software instead of hardware the components in charge of modulating and demodulating radio signals. The idea is to have a device that is able to receive and transmit different radio protocols just by configuring its software" />
        <Paragraph
          text={
            "Among the things that make the HackRF One so popular are its ability to receive and transmit radio in the 1Mhz to 6GHz range, its compatibility with Open-Source software such as GNU-Radio, having a notable community, well written documentation and its 'low' price."
          }
        />
        <Paragraph
          text={
            "Things like having such a wide range of operating frequency allows this device to be used with RF-emitting devices that can go from key fobs (which usually operate in the 300-450Mhz freq. range) to much more impressive things like monitoring SpaceX 🚀 rocket launches.  "
          }
        />
        <div className="w-full flex-col items-center flex justify-center">
          <img src="/me/spacex.png" className="w-full 2xl:w-3/4" />
          <p>
            *Figure :
            https://hackaday.com/2021/03/11/monitor-spacex-rocket-launches-with-software-defined-radio/
          </p>
        </div>
        <SemiTitle text={"Common Use Cases"} />
        <p className="text-text">
          So, come on and address the elephant in the room. What are these
          things really used for? Well, for the average user, SDR devices are
          capable of doing tons of different kind of things like amateur radio
          usage to communicate with your friends (be wary of local regulation
          regarding transmitting RF!), building your own GSM network or even
          listening to your favorite radio station.
        </p>
        <p className="text-text">
          When it comes to cyber security researching, devices like the HackRF
          One can be used to reverse engineer RF protocols that are being used
          all around us be it LTE or a simple RF protocol used in a garage key
          fob. While being a fun exercise, this can become a challenging task
          depending on the chosen protocol due to the fact that in order to get
          to the actual data someone would need to first find the signal, tune
          it, demodulate it, get the bits, deframe them and make sense out the
          resulting data. There are plenty of simple examples of RF reverse
          engineering projects on the internet for everyone to grasp the basics
          of RF and getting started with reversing using SDR devices. Now what
          to do when the protocol has successfully been reversed? Well, the sky
          is the limit! The functionality of the original device can be
          recreated to create your own client of the protocol or even improved
          by removing artificial limits imposed by the manufacturer to restrict
          features present in their higher end products.
        </p>
        <SemiTitle text={"How is it used by cyber criminals?"} />
        <p className="text-text">
          Cyber criminals are also known to use devices like the HackRF One
          (usually combined with accessories like the Portapack) to perform a
          wide variety of attacks such as "Jamming attacks" where the attacker
          emits noise to block the reception of a signal, "Replay attacks" where
          a signal is recorded and replayed without the original source device,
          "GPS spoofing" or even "Side channel attacks" that allow an attacker
          to remotely monitor what is being displayed on a monitor of a victim.
        </p>
        <SemiTitle text={"Needed Software"} />
        <p className="text-text">
          The HackRF One can be used in both Windows and Linux with widely
          available and well-maintained software. In the windows side of things,
          all that is needed to get started is is{" "}
          <a
            className="text-emerald-600 font-bold"
            href="https://airspy.com/download/"
          >
            #SDR
          </a>
        </p>
        <p className="text-text">
          In Linux, installing the driver packages is necessary to get the tools
          to communicate with the HackRF One. This can be done in Debian based
          distributions by simply installing using{" "}
          <code className="text-emerald-600 text-red-400">
            $ sudo apt install hackrf
          </code>{" "}
          package. Now to use the device to receive and record RF signals,{" "}
          <a className="text-emerald-600 font-bold" href="https://www.gqrx.dk/">
            Gqrx
          </a>{" "}
          is the open-source SDR# equivalent in Linux and it is also present in
          the Debian repos.
        </p>

        <p className="text-text">
          Going back to the main goal behind SDR devices, we talked about the
          idea of being able to receive and transmit different RF protocols with
          a single device just by configuring its software. Well, the programs
          that we just talked about only allow a set of predefined actions and
          configurations to be performed but, What about interacting with radio
          frequencies in a more programming-oriented way? This is where
          GNU-radio comes in.{" "}
          <a
            className="text-emerald-600 font-bold"
            href="https://www.gnuradio.org/"
          >
            GNU Radio
          </a>{" "}
          is an Open Source SDK that provides signal processing blocks that can
          be combined and interconnected in a graphical manner thus simplifying
          creating programs that work with RF.
        </p>
        <div className="w-full flex-col items-center flex justify-center">
          <img src="/me/gnuradio.png" className="w-full 2xl:w-3/4" />
          <p>*Figure : GNU Radio project example to capture Wifi packets </p>
        </div>
        <SemiTitle
          text={"How can this improve the quality of our evaluations?"}
        />
        <p className="text-text">
          There is no doubt that a product is as secure as its weakest link.
          Security measures implemented at a higher level can be rendered
          useless by existing low-level attacks such as the one we discussed
          above and it is clear that effort should also be put into protecting
          components and its communications at a lower level.
        </p>

        <p className="text-text">
          Being able to add an SDR device to the catalog of tools that the
          evaluation team at jtsec has as its disposal allows us to perform more
          exhaustive testing on communication fo the product communications to
          ensure that the highest levels of security are met.
        </p>

        <div className="flex flex-row justify-between">
          <div className="flex flex-row">
            {/* <ButtonVisit text="Feedback?" /> */}
            <EmojiBtn text="like" />
            <EmojiBtn text="share" />
          </div>
          {/* <EmojiBtn text="donate" /> */}
        </div>
      </SinglePost>
    </>
  );
};

export default HackRfAttacks;
