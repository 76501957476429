/* eslint-disable react/style-prop-object */
import React from "react";

import Paragraph from "../../utils/Paragraph";
import SemiTitle from "../../utils/SemiTitle";
import Title from "../../utils/Title";
import { BlogData } from "../Data";
import SinglePost from "../../utils/SinglePost";
import EmojiBtn from "../../utils/EmojiBtn";
import ButtonVisit from "../../utils/ButtonVisit.";
import { Helmet } from "react-helmet";

const X11Forward = () => {
  return (
    <>
      <Helmet>
        <title>{BlogData[1].name}</title>
        <meta name="description" content={BlogData[1].category} />
      </Helmet>
      <SinglePost
        postTitle={BlogData[1].name}
        Postimg={BlogData[1].img}
        postDate={BlogData[1].date}
      >
        <SemiTitle text={"What the heck is X11 Forwarding??"} />
        <Paragraph text="X11 forwarding is a mechanism that allows a user to start up remote applications, and then forward the application display to their local Windows machine." />
        <SemiTitle text={"X11Forwarding"} />
        <p className="text-text">
          For this to work, we have to allow X11 forwarding in the SSH
          configuration file{" "}
          <code className="text-red-400   tracking-widest">
            (/etc/ssh/sshd_config){" "}
          </code>
          on the server that provides the application by changing this option to
          yes..
        </p>{" "}
        <div className="flex justify-center">
          <img src="/me/x11.png" className="w-full 2xl:w-3/4" />
        </div>
        <SemiTitle text={"Usage"} />
        <Paragraph
          text={
            "With this we can start the application from our client with the following command:"
          }
        />
        <div className="flex justify-center">
          <img src="/me/x11-2.png" className="w-full 2xl:w-3/4" />
        </div>
        <SemiTitle text={"X11 Security"} />
        <Paragraph
          text={
            "X11 is insecure without appropriate measures, as it communicates unencrypted. An open X server can expose window contents to anyone on the network, allowing tools like xwd and xgrabsc to capture keystrokes, screenshots, and control the mouse remotely."
          }
        />
        <SemiTitle text={"Exploited XServer Vulnerabilities"} />
        <Paragraph
          text={
            "Vulnerabilities in XServer have been exploited to execute arbitrary code with user privileges on UNIX and Linux systems, including Red Hat Enterprise Linux, Ubuntu, and SUSE Linux. Notable vulnerabilities include CVE-2017-2624, CVE-2017-2625, and CVE-2017-2626."
          }
        />
        <div className="flex flex-row justify-between select-none">
          <div className="flex flex-row select-none">
            {/* <ButtonVisit text="Feedback?" /> */}
            <EmojiBtn text="like" />
            <EmojiBtn text="share" />
          </div>
          {/* <EmojiBtn text="donate" /> */}
        </div>
      </SinglePost>
    </>
  );
};

export default X11Forward;
