import { motion } from "framer-motion";
import React from "react";
import img1 from "../../assets/tesla/1.jpg";
import img2 from "../../assets/tesla/2.jpg";
import RakBank from "../../assets/rakbank.jpg";
import img3 from "../../assets/tesla/3.jpg";
import ImgSlider from "../../utils/ImgSlider";
import { BsBriefcase } from "react-icons/bs";
import ButtonVisit from "../../utils/ButtonVisit.";
import EmojiBtn from "../../utils/EmojiBtn";
import { Helmet } from "react-helmet";

const RAKBANKxMushraf = () => {
  const imglist = [img1, img2, img3, img2];
  return (
    <div className="min-h-screen max-w-[650px] mx-auto font-primary pb-32 2xl:pb-20">
      <Helmet>
        <title>Experience at RAK BANK</title>
        <meta name="description" content="Experience at RAK BANK - Mushraf" />
      </Helmet>

      <div className="flex justify-between items-center sm:pt-[170px]  pt-[70px] 2xl:pt-8 pb-12">
        <motion.h2
          variants={{
            hidden: { opacity: 0, x: 50, y: 50, scale: 0.68 },
            visible: { opacity: 1, x: 0, y: 0, scale: 1 },
          }}
          initial="hidden"
          animate="visible"
          transition={{ duration: 0.75, delay: 0.25 }}
          className="text-text text-[40px] font-light"
        >
          RAK BANK
          <br />
          <div className="hidden md:block text-sm text-[#6A6A6A]">
            National Bank of Ras Al Khaimah - United Arab Emirates
          </div>
        </motion.h2>
        <motion.a
          variants={{
            hidden: { opacity: 0, x: -50, y: 50, scale: 0.68 },
            visible: { opacity: 1, x: 0, y: 0, scale: 1 },
          }}
          initial="hidden"
          animate="visible"
          transition={{ duration: 0.75, delay: 0.25 }}
          href="#"
          className="inline text-text    text-sm border-2 border-customBorder rounded-[30px] p-1  cursor-pointer"
        >
          <img src={RakBank} className=" rounded-3xl  w-24" />
        </motion.a>
      </div>
      <motion.h2
        variants={{
          hidden: { opacity: 0, x: 50, y: 50, scale: 0.68 },
          visible: { opacity: 1, x: 0, y: 0, scale: 1 },
        }}
        initial="hidden"
        animate="visible"
        transition={{ duration: 0.75, delay: 0.25 }}
      >
        <div className="w-full flex flex-row justify-between pb-5 text-[#6A6A6A]">
          <div>Started: 2018</div>

          <div>Ended: 2019</div>
        </div>

        <div className="w-full  flex flex-row justify-center pb-10">
          <div className="flex gap-4 flex-row  ">
            {/* <div className="  text-2xl">
            <BsBriefcase />
          </div> */}
            <div className="text-text ">
              SOC Analyst - Security Operations Center
            </div>
          </div>
        </div>
      </motion.h2>
      {/* <motion.div
        variants={{
          hidden: {
            opacity: 0,
            paddingTop: 100,
            transform: "skewY(0.04rad)",
            scale: 0.4,
          },
          visible: {
            opacity: 1,
            paddingTop: 0,
            transform: "skewY(0)",
            scale: 1,
          },
        }}
        initial="hidden"
        animate="visible"
        transition={{
          delay: 0.4,
          duration: 1,
        }}
      >
        <ImgSlider imgList={imglist} />
      </motion.div> */}
      <hr />
      <motion.div
        variants={{
          hidden: {
            opacity: 0,
            paddingTop: 100,
            transform: "skewY(0.04rad)",
            scale: 0.4,
          },
          visible: {
            opacity: 1,
            paddingTop: 0,
            transform: "skewY(0)",
            scale: 1,
          },
        }}
        initial="hidden"
        animate="visible"
        transition={{
          delay: 0.4,
          duration: 1,
        }}
        className="flex flex-col gap-8 mt-16"
      >
        <h3 className="text-text text-2xl font-normal">Summary</h3>
        <p className="text-text text-[15px] leading-[26px] font-normal">
          During my service I developed SOC automation strategies and performed
          incident response and threat hunting to enhance security posture; Also
          conducted security assessments and compliance tests.
        </p>
        {/* <p className="text-text text-[15px] leading-[26px] font-normal">
          I also performed log analysis to enhance our cybersecurity posture.
          Additionally, I led penetration testing and reverse engineering
          efforts to identify and mitigate security risks.
        </p> */}
        <motion.div
          variants={{
            hidden: {
              opacity: 0,
              paddingTop: 100,
              transform: "skewY(0.04rad)",
              scale: 0.4,
            },
            visible: {
              opacity: 1,
              paddingTop: 0,
              transform: "skewY(0)",
              scale: 1,
            },
          }}
          initial="hidden"
          animate="visible"
          transition={{
            delay: 0.4,
            duration: 1,
          }}
        >
          {/* <ImgSlider imgList={imglist} /> */}
        </motion.div>
        <h6 className="text-text text-lg font-normal">Key Tasks</h6>
        <p className="text-text text-[15px] leading-[26px] font-normal">
          My main responsibilities in this role are:
        </p>
        <p className="text-text text-[15px] leading-[26px] font-normal">
          <ul className=" px-2">
            <li className=" list-disc">
              Patch management and vulnerability management of Bank’s assets
              ~4k. Nessus, Qualys and MS.
            </li>
            <li className=" list-disc">
              Working on reported IOCs and updating in Bank’s database.
            </li>
            <li className=" list-disc">
              Conducted secure configuration test on IT infrastructure ~2k
              assets using Nessus and Qualys..
            </li>
            <li className=" list-disc">
              Conducted assessments per PCI DSS requirement #11 for ~600 assets.
            </li>
            <li className=" list-disc">
              {" "}
              Developed and implemented the scanning and reporting strategy and
              tool for workflow automation of SOC.{" "}
            </li>
            <li className=" list-disc">
              {" "}
              Conducted vulnerability assessments on Bank’s servers, network
              devices and endpoints in scope.
            </li>
            <li className=" list-disc">
              {" "}
              Log Analysis from various network devices and applications.{" "}
            </li>
            <li className=" list-disc">
              Perform attack simulations on company systems and web applications
              to determine the exploit risk & security flaws.
            </li>
            <li className=" list-disc">
              Document and discuss security findings with information technology
              teams.
            </li>
            <li className=" list-disc">
              Analysing triaged malicious programs and code by conducting
              reverse engineering techniques and tools, as well as checking
              behaviour and testing same in virtual machines.
            </li>{" "}
            <li className=" list-disc">
              Perform Security SIEM Operational task - Analysis, Filters, Active
              channels, Reports, Suggestion of fine tuning on existing rules and
              monitor IOC (Indicators of Compromise).
            </li>{" "}
          </ul>
        </p>

        {/* <h6 className="text-text text-lg font-normal">Keywords</h6> */}
        <p className="text-text text-[15px] leading-[26px] font-normal">
          <span className=" font-bold">Skills</span> Patch management,
          vulnerability management, automation of SOC, log analysis, reverse
          engineering, SIEM, IOC.
        </p>
        <div className="flex row gap-5 flex-wrap">
          <ButtonVisit text="Visit EARTH RETAIL" link="https://rakbank.ae" />
          <ButtonVisit text="Interested??" link="/contact" />

          <EmojiBtn />
          <EmojiBtn text="share" />
        </div>
      </motion.div>
    </div>
  );
};

export default RAKBANKxMushraf;
