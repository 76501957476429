/* eslint-disable react/style-prop-object */
import React, { useEffect } from "react";

import { Helmet } from "react-helmet";

const Call = () => {
  useEffect(() => {
    const head = document.querySelector("head");
    const script = document.createElement("script");
    script.setAttribute(
      "src",
      "https://assets.calendly.com/assets/external/widget.js"
    );
    head.appendChild(script);
  }, []);
  return (
    <>
      <Helmet>
        <title>Schedule a Meeting</title>
        <meta
          name="description"
          content="Schedule a 30 Minute meeting with Mushraf"
        />
      </Helmet>

      <div className="   w-full h-screen flex justify-center items-center ">
        <div
          class="calendly-inline-widget w-full"
          data-url="https://calendly.com/mushraf-dev/30min?hide_event_type_details&hide_gdpr_banner=1&background_color=1a1a1a&text_color=ffffff&primary_color=6B9EDB"
          style={{ minWidth: "320px", height: "700px" }}
        ></div>
      </div>
    </>
  );
};

export default Call;
