import { motion } from "framer-motion";
import React from "react";
import boy from "../assets/cybergenius_01.jpeg";
import Accordion from "../utils/Accordion";
import Reveal from "../utils/Reveal";
import ReviewSlider from "../utils/ReviewSlider";
import Title from "../utils/Title";
import { Helmet } from "react-helmet";

const projectData = [
  { name: "Years into cyber ops", count: 6 },
  { name: "Recognizations", count: "50+" },
  { name: "Projects", count: 20 },
];

const visionData = [
  {
    title: "Privacy Projects",
    desc: "To build scalable privacy-focused projects that revolutionize the cybersecurity world.To provide an avenue for creators and developers like me to build and pursue their passion. ",
  },
  {
    title: "Educate and Raise Awareness",
    desc: "To educate individuals and organizations about the importance of cybersecurity and best practices for protection.",
  },
  {
    title: "Make a Positive Impact",
    desc: "To produce meaningful work that empowers and contributes to the cybersecurity field.",
  },
  {
    title: "Support Green Initiatives",
    desc: "Supporting eco friendly initiatives like ",

    link: "https://growyourown.cloud/data-garden/#", // URL for the link
    linkText: "growyourown.cloud/data-garden/ 🔗", // Text for the link
  },
];

const About = () => {
  return (
    <div className="min-h-screen flex flex-col justify-center items-center pb-32 md:pb-20">
      <Helmet>
        <title>About</title>
      </Helmet>

      <Reveal>
        <Title text={"Meet Mushraf"} />
      </Reveal>

      <div className="w-full sm:max-w-[650px] 2xl:max-w-[1080px] font-primary flex justify-center  flex-col gap-7">
        <div className=" flex w-full justify-center">
          <motion.img
            initial={{
              opacity: 0,
              paddingTop: 100,
              transform: "skewY(0.04rad)",
              scale: 0.6,
            }}
            animate={{
              opacity: 1,
              paddingTop: 0,
              transform: "skewY(0)",
              scale: 1,
            }}
            transition={{
              delay: 0.2,
              type: "spring",
              duration: 3,
            }}
            src={"/me/about.jpg"}
            alt="profile-pic"
            className="rounded-xl  mb-4 w-full 2xl:w-[750px] h-80 object-cover object-[90%_30%]"
          />
        </div>
        <Reveal>
          <h3 className="text-text text-2xl">
            Mushraf is a Sweden-based cyber security expert with a passion for
            developing privacy-focused tools and real-life projects.
          </h3>
          <p className="text-text text-lg mt-5 md:tracking-wide  md:leading-8">
            He is passionate about what he does and mostly self-taught; kind of
            guy who will make sure your data is protected with the same
            meticulous care coding your SaaS applications.
          </p>
          <p className="text-text text-lg mt-5 md:tracking-wide  md:leading-8">
            Over 6+ years of experience in the industry, He has been recognized
            by several companies of leading tech giants such as Dell, Sony,
            Intel, etc. for finding vulnerabilities in their software as a
            seasonal bug hunter. This earned him a reputation as a trusted
            expert in the field.
          </p>
          <p className="text-text text-lg  md:tracking-wide  md:leading-8 mt-5">
            As a side hobby Mushraf has been creating immersive websites which
            includes 3D SaaS and Web products. Having security background it
            helps follow best practicies and make secure scaleable applications.{" "}
          </p>
        </Reveal>
        <Reveal Style="w-full flex justify-center items-center md:flex-row flex-col gap-8 md:gap-32 py-2">
          {projectData.map((item, index) => (
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{
                delay: 0.5 + index / 3,
                type: "spring",
                duration: 2,
              }}
              key={index}
              className="text-center"
            >
              <p className="text-6xl text-text font-extralight pb-3">
                {item.count}
              </p>
              <p className="text-[#6A6A6A] text-sm font-normal">{item.name}</p>
            </motion.div>
          ))}
        </Reveal>
        {/* vision */}
        <Reveal Style="flex justify-center  flex-col gap-7 pt-4">
          <h3 className="text-text text-2xl text-left">Vision</h3>
          <p className="text-text">
            Empowering everyone, tech-savvy or not, with the best privacy and
            cutting-edge tools in market to safeguard against cyber attacks.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 font-normal">
            {visionData.map((item, index) => (
              <div key={index}>
                <h3 className="text-text text-lg">{item.title}</h3>
                <p className="text-[#6A6A6A] text-xs">
                  {item.desc}
                  {item.link && (
                    <>
                      {" "}
                      <a href={item.link} className="text-emerald-500 ">
                        {item.linkText}
                      </a>
                    </>
                  )}
                </p>
              </div>
            ))}
          </div>

          <p className="text-xl text-teal-50 text-center  ">
            Privacy is your Right not a Privilege
          </p>
        </Reveal>
        {/* services */}
        <div className="py-10">
          <ReviewSlider />
        </div>

        <Reveal Style="flex justify-center  flex-col gap-7">
          <h3 className="text-text text-2xl text-left">On Demand Services</h3>
          <Accordion />
          <div className="pb-10">
            <a
              href="/contact"
              className="inline text-main  text-sm border-2 border-customBorder rounded-[30px] px-8 py-[14px]  cursor-pointer custom-link-hover"
            >
              Get in Touch
            </a>
          </div>
        </Reveal>
      </div>
    </div>
  );
};

export default About;
