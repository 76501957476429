import { motion } from "framer-motion";
import React from "react";
import img1 from "../../assets/tesla/1.jpg";
import img2 from "../../assets/tesla/2.jpg";
import Earth from "../../assets/earth.jpeg";

import img3 from "../../assets/tesla/3.jpg";
import ImgSlider from "../../utils/ImgSlider";
import { BsBriefcase } from "react-icons/bs";
import ButtonVisit from "../../utils/ButtonVisit.";
import EmojiBtn from "../../utils/EmojiBtn";
import { Helmet } from "react-helmet";

const EARTHxMushraf = () => {
  const imglist = [img1, img2, img3, img2];
  return (
    <div className="min-h-screen max-w-[650px] mx-auto font-primary pb-32 2xl:pb-20">
      <Helmet>
        <title>Experience at Earth Retail </title>
        <meta
          name="description"
          content="Experience at Earth Retail - Mushraf"
        />
      </Helmet>
      <div className="flex justify-between items-center sm:pt-[170px]  pt-[70px] 2xl:pt-8 pb-12">
        <motion.h2
          variants={{
            hidden: { opacity: 0, x: 50, y: 50, scale: 0.68 },
            visible: { opacity: 1, x: 0, y: 0, scale: 1 },
          }}
          initial="hidden"
          animate="visible"
          transition={{ duration: 0.75, delay: 0.25 }}
          className="text-text text-[40px] font-light"
        >
          Earth Retail <br />
          <div className="text-sm text-[#6A6A6A]">
            Abu Dhabi, United Arab Emirates
          </div>
        </motion.h2>
        <motion.a
          variants={{
            hidden: { opacity: 0, x: -50, y: 50, scale: 0.68 },
            visible: { opacity: 1, x: 0, y: 0, scale: 1 },
          }}
          initial="hidden"
          animate="visible"
          transition={{ duration: 0.75, delay: 0.25 }}
          href="#"
          className="inline text-text    text-sm border-2 border-customBorder rounded-[30px] p-1  cursor-pointer"
        >
          <img src={Earth} className=" rounded-3xl  h-20" />
        </motion.a>
      </div>
      <motion.h2
        variants={{
          hidden: { opacity: 0, x: 50, y: 50, scale: 0.68 },
          visible: { opacity: 1, x: 0, y: 0, scale: 1 },
        }}
        initial="hidden"
        animate="visible"
        transition={{ duration: 0.75, delay: 0.25 }}
      >
        <div className="w-full flex flex-row justify-between pb-5">
          <div>Started: Feb 2021</div>

          <div>Ended: Dec 2021</div>
        </div>

        <div className="w-full  flex flex-row justify-center pb-10">
          <div className="flex gap-4 flex-row  ">
            {/* <div className="  text-2xl">
            <BsBriefcase />
          </div> */}
            <div className="text-text ">IT Security Practicioner</div>
          </div>
        </div>
      </motion.h2>
      {/* <motion.div
        variants={{  
          hidden: {
            opacity: 0,
            paddingTop: 100,
            transform: "skewY(0.04rad)",
            scale: 0.4,
          },
          visible: {
            opacity: 1,
            paddingTop: 0,
            transform: "skewY(0)",
            scale: 1,
          },
        }}
        initial="hidden"
        animate="visible"
        transition={{
          delay: 0.4,
          duration: 1,
        }}
      >
        <ImgSlider imgList={imglist} />
      </motion.div> */}
      <hr />
      <motion.div
        variants={{
          hidden: {
            opacity: 0,
            paddingTop: 100,
            transform: "skewY(0.04rad)",
            scale: 0.4,
          },
          visible: {
            opacity: 1,
            paddingTop: 0,
            transform: "skewY(0)",
            scale: 1,
          },
        }}
        initial="hidden"
        animate="visible"
        transition={{
          delay: 0.4,
          duration: 1,
        }}
        className="flex flex-col gap-8 mt-16"
      >
        <h3 className="text-text text-2xl font-normal">Summary</h3>
        <p className="text-text text-[15px] leading-[26px] font-normal">
          Throughout my tenure, I have successfully managed vulnerability
          assessments and secure configurations for retail POS.
        </p>
        <p className="text-text text-[15px] leading-[26px] font-normal">
          Pre launching of stores and their IT equipment testing were one of
          field in exceled in ensuring comprehensive security and seamless
          operations..
        </p>
        <motion.div
          variants={{
            hidden: {
              opacity: 0,
              paddingTop: 100,
              transform: "skewY(0.04rad)",
              scale: 0.4,
            },
            visible: {
              opacity: 1,
              paddingTop: 0,
              transform: "skewY(0)",
              scale: 1,
            },
          }}
          initial="hidden"
          animate="visible"
          transition={{
            delay: 0.4,
            duration: 1,
          }}
        >
          {/* <ImgSlider imgList={imglist} /> */}
        </motion.div>
        <h6 className="text-text text-lg font-normal">Key Tasks</h6>
        <p className="text-text text-[15px] leading-[26px] font-normal">
          Some of top level tasks were:
        </p>
        <p className="text-text text-[15px] leading-[26px] font-normal">
          <ul className=" px-2">
            <li className=" list-disc">
              Managing a focus on vulnerability management (~300 Assets),
              Prelaunch secure configuration and VAPT of assets including
              servers, endpoint POS, cloud services and network devices.
            </li>
            <li className=" list-disc">
              Facilitating various integration of security and IT tools as and
              when required by the management.
            </li>
            <li className=" list-disc">
              Vendor coordination for new assets, bugs and tickets.
            </li>
            <li className=" list-disc">
              Hardened and operationalize (~80 Assets) over my course of
              engagement with company.
            </li>
            <li className=" list-disc">
              Assisting with scoping engagements and participating in various
              POCs and implementations completion including 3rd party
              assessments
            </li>
          </ul>
          • Document and discuss security findings with information technology
          teams. • Analysing triaged malicious programs and code by conducting
          reverse engineering techniques and tools, as well as checking
          behaviour and testing same in virtual machines. • Perform Security
          SIEM Operational task - Analysis, Filters, Active channels, Reports,
          Suggestion of fine tuning on existing rules and monitor IOC
          (Indicators of Compromise).
        </p>
        {/* Skill / Keywords / Button */}
        <p className="text-text text-[15px] leading-[26px] font-normal">
          <span className=" font-bold">Skills</span> Patch management,
          vulnerability management, third party assessments, secure
          configuration,asset mnagement.
        </p>
        <div className="flex row gap-5 flex-wrap">
          <ButtonVisit
            text="Visit EARTH RETAIL"
            link="https://earthretail.ae"
          />
          <ButtonVisit text="Interested??" link="/contact" />

          <EmojiBtn />
          <EmojiBtn text="share" />
        </div>
      </motion.div>
    </div>
  );
};

export default EARTHxMushraf;
