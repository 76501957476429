/* eslint-disable react/style-prop-object */
import { motion } from "framer-motion";
import React from "react";
import boy from "../assets/cyybergenius_05.jpeg";
import Title from "../utils/Title";
import { Helmet } from "react-helmet";

const contactData = [
  { name: " Drop an E-Mail", link: "mailto:mushraf.dev@gmail.com" },
  { name: " Have a call", link: "https://calendly.com/mushraf-dev/30min" },
  // { name: "Newsletter", link: "https://mailchimp.com/" },
];

const socialData = [
  {
    name: "Twitter",
    link: "#",
  },
  {
    name: "Instagram",
    link: "#",
  },
  {
    name: "Linkedin",
    link: "#",
  },
];

const Contact = () => {
  return (
    <div className="min-h-screen flex flex-col justify-center items-center pb-32 md:pb-20">
      <Helmet>
        <title>Get in Touch - Contact </title>
        <meta name="description" content="Get in Touch" />
      </Helmet>
      <motion.div
        variants={{
          hidden: { opacity: 0, marginTop: "25px", scale: 0.68 },
          visible: { opacity: 1, marginTop: 0, scale: 1 },
        }}
        initial="hidden"
        animate="visible"
        transition={{ duration: 1, delay: 0.25 }}
      >
        <Title text={"The Stage is Yours"} style={"mb-[20px]"} />
      </motion.div>
      <div className="w-full sm:max-w-[650px] font-primary ">
        <motion.h2
          variants={{
            hidden: { opacity: 0, marginTop: "25px", scale: 0.68 },
            visible: { opacity: 1, marginTop: 0, scale: 1 },
          }}
          initial="hidden"
          animate="visible"
          transition={{ duration: 1, delay: 0.35 }}
          className="text-text text-center font-normal text-lg mb-[60px]"
        >
          I’m always looking to collaborate on interesting projects with great
          people. Need a supportive hand? I have two!
        </motion.h2>
        <motion.div
          variants={{
            hidden: { opacity: 0, marginTop: "25px", scale: 0.68 },
            visible: { opacity: 1, marginTop: 0, scale: 1 },
          }}
          initial="hidden"
          animate="visible"
          transition={{ duration: 1, delay: 0.55 }}
          className="border-2 border-navBorder rounded-2xl p-[40px] select-none flex flex-col justify-center items-center text-center gap-2 workShadow"
        >
          <img
            src={boy}
            alt="MM"
            width={100}
            height={150}
            className=" rounded-full object-cover object-left-top w-[100px] h-[100px]"
          />
          <h3 className="text-text  text-2xl mt-6">Mushraf</h3>
          <p className="text-[#6A6A6A]">
            Cyber Security Expert and Web Developer.
          </p>
          <div className="flex sm:justify-center sm:items-center sm:flex-row flex-col gap-4 mt-3 w-full">
            {contactData.map((item, index) => (
              <motion.a
                whileHover={{ borderColor: "rgba(218, 218, 218, 0.2)" }}
                href={item.link}
                key={index}
                className="block text-text  text-sm border-2 border-customBorder rounded-3xl p-3 w-full sm:w-[150px] cursor-pointer"
              >
                {item.name}
              </motion.a>
            ))}
          </div>
        </motion.div>
        <div className="py-10 flex justify-center items-center gap-8">
          {/* {socialData.map((item, index) => (
            <a
              key={index}
              href={item.link}
              target="_blank"
              className="text-text text-xs hoverText after:absolute after:h-[1px] after:bottom-[-1px] after:left-0 border-b-[1px] border-customBorder"
            >
              {item.name}
            </a>
          ))} */}
        </div>
      </div>
      <div className="flex flex-col items-center  gap-4 text-text">
        <img src="/logomm.png" width={70} className=" invert " loading="lazy" />
        <p className="text-sm"> Mushraf - All rights reserved</p>
      </div>
    </div>
  );
};

export default Contact;
